.playerPhotoIcon {
    margin: 0px auto 20px;
    display: block;
}

.playerPhotoIcon img {
    width: 65%;
    height: auto;
    margin: 0 auto;
    display: block;
}