@font-face {
  font-family: "RomanaBT-Bold";
  src: url("./assets/fonts/RomanaBT-Bold.eot");
  src: url("./assets/fonts/RomanaBT-Bold.woff2") format("woff2"),
    url("./assets/fonts/RomanaBT-Bold.woff") format("woff"),
    url("./assets/fonts/RomanaBT-Bold.ttf") format("truetype"),
    url("./assets/fonts/RomanaBT-Bold.eot?#iefix") format("embedded-opentype");
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Regular.eot");
  src: url("./assets/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Regular.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Regular.woff") format("woff"),
    url("./assets/fonts/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("./assets/fonts/Montserrat-Bold.eot");
  src: url("./assets/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Bold.woff") format("woff"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

* { 
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  margin: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

.pageContainer {
  background-color: #262E60;
  min-height: 100dvh;
  text-align: center;
}

.logoContainer {
  text-align: center;
  padding: 20px;
}

footer {
  margin: 20px 0 0;
  padding: 0 0 40px 0;
  background-color: #262E60;
  display: block;
}

button {
  font-size: 25px;
  font-family: RomanaBT-Bold, Romana BT;
  font-weight:500;
  color: #262E60;
  background-color: #5AD2C8;
  border-radius: 8px;
  min-width: 228px;
  height: 50px;
  line-height: 50px;
  margin: 0 auto;
  padding: 0;
  border: 2px solid transparent;
  display: block;
}

button:disabled {
  opacity: 0.2;
}

.alt-button {
  background-color: #262E60;
  color: #5AD2C8;
  border: 2px solid #5AD2C8;
  box-sizing: border-box;
}

.second-button {
  margin-top: 12px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  text-align: center;
}

input[type="text"] {
  font-size: 24px;
  padding: 10px;
  border: 1px solid #5AD2C8;
  width: 75%;
  height: 50px;
  box-sizing: border-box;
  color: #ffffff;
  background-color: #262E60;
  text-transform: uppercase;
  text-align: left;
}

input:auto-fill {
  border: 1px solid #ffffff;
  color: #ffffff;
  background-color: #262E60;
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid #ffffff;
    color: #ffffff;
    background-color: #262E60;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #262E60 inset;
  transition: background-color 5000s ease-in-out 0s;
}

::placeholder {
  color: #ffffff;
  opacity: 1; /* Firefox */
}

.errorContainer {
  width: 80%;
  margin: 40px auto;
}

.errorTitleText {
    font-size: 25px;
    font-family: RomanaBT-Bold, Romana BT;
    font-weight:500;
    letter-spacing: 2px;
    color: #ffffff;
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin: 40px 0;
}

.errorText {
  text-align: center;
}

.clearfix:after {
  content:"";
  display:block;
  clear:both;
 }