.completeText {
    text-align: center;
    width: 80%;
    margin: 15px auto 5px;
    font-size: 16px;
    line-height: 19px;
}

.morePlayersText {
    text-align: center;
    width: 80%;
    margin: 0px auto 10px;
    font-size: 16px;
    line-height: 22px;
}

.completeEmail {
    color: #5AD2C8;
}   

.complete {
    width: 220px;
    height: 236px;
    margin: 20px auto 0;
  }
  
  .complete .playerPhotoMask {
    width: 296px;
    left: -39px;
  }
  
  .complete .playerPhotoBorder {
    width: 100%;
    height: 100%;
  }