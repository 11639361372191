.progressContainer {
    list-style: none;
    margin: 0px auto 20px;
    position: relative;
    width: 100%;
    display: inline-block;
  }
  
  .progressButton {
    float:left;
    position: relative;
    width: 33%;
    text-align: center;
  }
  
  .progressButton::before {
    content:"";
    background: #A541EB;
    border:none;
    height: 2px;
    position: absolute;
    left: -50%;
    top: 9px;
    width: 100%;
  }
  
  .progressButton-border {
    width: 30px;
    height: 20px;
    position: relative;
  }
  
  .progressButton-dot {
    position: relative;
    width: 20px;
    height: 20px;
    background: #262E60;
    border: 2px solid #A541EB;
  }
  
  .progressLabel {
    margin: 5px 0;
    font-size: 16px;
    color: #A541EB;
  }
  
  .progressButton-border, .progressButton-dot {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    
  }
  
  .button-complete .progressButton-dot{
    background: #5AD2C8;
    border: 4px solid #ffffff;
  }
  
  .button-complete .progressLabel {
    color: #ffffff;
  }
  
  .button-complete:first-child::before {
    display: none;
  }
  
  .button-complete::before {
    content:"";
    background: #ffffff;
    border:none;
    height: 2px;
    position: absolute;
    top: 9px;
  }
  
  .button-active .progressButton-dot {
    background: #5AD2C8;
    border: 4px solid #ffffff;
    z-index: 2;
  }
  
  .button-active .progressLabel {
    color: #ffffff;
  }