.altPage input[type="text"] {
    color: #ffffff;
    background-color: #262E60;
    border: 1px solid #ffffff;
    margin: 0 auto 10px;
}

.altPage input:auto-fill {
    border: 1px solid #ffffff;
    color: #ffffff;
    background-color: #262E60;
}

.altPage .formContainer input:-webkit-autofill,
.altPage .formContainer input:-webkit-autofill:hover,
.altPage .formContainer input:-webkit-autofill:focus {
    border: 1px solid #ffffff;
    color: #ffffff;
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0px 1000px #262E60 inset;
    background-color: #262E60;
}

.altPage .formHeader {
    color: #A541EB;
    font-size: 16px;
    margin: 10px auto;
}


.altPage .formContainer ::placeholder {
    color: #ffffff;
    opacity: 1;
    /* Firefox */
}

.altPage footer {
    margin-top: 0px;
}

.altPage button {
    background-color: #A541EB;
    color: #ffffff;
    width: 75%;
}

.altPage ul {
    width: 70%;
    margin: 10px auto;
}

li {
    list-style: none;
    margin: 2px 0;
    padding: 0;
    text-align: left;
}


li::before {
  content: '';
  display: inline-block;
  height: 12px;
  width: 20px;
  margin: 0 8px;
  background-image: url('../assets/img/form_tick.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
}

.topBlock {
    background-color: #ffffff;
    margin: 0 auto;
    padding: 10px 0;

}

.playerWelcomeContainer {
    position: relative;
    width: 300px;
    margin: 0 auto 5px;
    text-align: left;
  }
  
  .playerWelcomeContainer .playerPhotoContainer {
    width: 140px;
    height: 150px;
    margin-left: 0;
    margin-right: 0;
  }
  
  .playerWelcomeContainer .playerPhotoContainer .playerPhotoMask {
    width: 190px;
    left: -25px;
  }
  
  .playerWelcomeContainer .playerPhotoContainer .playerPhotoBorder {
    width: 100%;
    height: 100%;
  }

  .playerWelcomeContainer .playerPhotoContainer .playerPhotoBorder img {
    filter: none;
  } 
  
  .playerNameBanner {
    font-size: 22px;
    font-family: 'Montserrat', sans-serif;
    font-weight:500;
    color: #ffffff;
    background-image: url('../assets/img/player_enters_banner.svg');
    background-repeat: no-repeat;
    background-size: contain;
    text-transform: uppercase;
    width: 200px;
    height: 44px;
    line-height: 44px;
    padding-left: 44px;
    position: absolute;
    top:53px;
    left:102px;
  }
  
  .playerNameBannerCenter {
    font-size: 24px;
    font-family: 'Montserrat', sans-serif;
    font-weight:500;
    color: #ffffff;
    background-image: url('../assets/img/name_bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    text-transform: uppercase;
    width: 286px;
    height: 46px;
    line-height: 46px;
    text-align: center;
    margin: -5px auto 0;
    display: inline-block;
  }
  
  .playerEnteredText {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight:500;
    color: #A541EB;
    text-transform: uppercase;
    width: 160px;
    position: absolute;
    top:100px;
    left:145px;
  }

  .playerEnteredSuccess {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight:500;
    color: #A541EB;
    text-transform: uppercase;
    width: 160px;
    position: absolute;
    top:27px;
    left:145px;
  }

  .playerEnteredLobby {
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight:500;
    color: #ffffff;
    position: relative;
    margin: 0 auto;
    padding: 20px 0 0;
  }

  .playerEnteredLobbyInner {
    padding: 12px 0;
    border: 3px #A541EB solid;
    border-radius: 8px;
    width: 75%;
    margin: 0 auto;
  }

  .successHeader {
    font-size: 25px;
    font-family: RomanaBT-Bold, Romana BT;
    margin: 0 0 10px;
  }

  .successBody {
    font-size: 16px;
  }
  
  .playerEnteredTableText {
    width: 100%;
    text-align: center;
    margin: 60px 0 20px;
  }

  .storyContainer {
    width: 87%;
    margin: 0px auto 5px;
    padding: 7px 0px;

  }

  .storyContainerHeader {
    font-size: 25px;
    font-family: RomanaBT-Bold, Romana BT;
    margin: 20px 0 0;
  }

  .storyContainer img {
    width: inherit;
    height: auto;
    margin: 0 auto;
    display: block;
  }